<template>
  <div>
    <!-- แถบแรก -->
    <b-card no-body>
      <b-card-header>
        <h4 class="card-title">
          รายการ Logs วงล้อ
        </h4>
      </b-card-header>
      <div class="card-content">
        <b-card-body>
          <!-- Table Function -->
          <b-row class="mx-auto mb-1">
            <b-col cols="12 col-md-10" class="pr-1 pl-1">
              <div>
                <b-input-group>

                  <b-input-group-prepend>
                    <span class="input-group-text" label="date_start_report"> วันที่ (จาก) </span>
                  </b-input-group-prepend>
                  <flat-pickr v-model="date_start_report" id="date_start_report" class="form-control text-center" />

                  <b-input-group-prepend>
                    <span class="input-group-text" label="date_end_report"> วันที่ (ถึง) </span>
                  </b-input-group-prepend>
                  <flat-pickr v-model="date_end_report" id="date_end_report" class="form-control text-center" />

                </b-input-group>
              </div>
            </b-col>
            <b-col cols="12 col-md-2">
              <div class="btn-group">
                <b-button @click="fetch()" variant="btn-primary" class="btn bg-gradient-primary waves-effect waves-light"> ค้นหา </b-button>
              </div>
            </b-col>
          </b-row>
          <!-- End Table Function -->
          <!-- Table Header -->
          <b-row>
            <b-col md="3" sm="2" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">แสดง</label>
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" v-on:change="onPageSelect" style="width: 60px"/>
                <label class="d-inline-block text-sm-right ml-50">รายการ</label>
              </b-form-group>
            </b-col>
            <b-col md="5" sm="6" class="my-1" />
            <b-col md="4" sm="4" class="my-1">
              <b-form-group label="ค้นหา :" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0" >
                <b-input-group size="sm">
                  <b-form-input id="filterInput" v-model="filter" v-on:change="onPageSelect" type="search" />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"> ล้าง </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- End Table Header -->
          <!-- Table Body -->
          <b-row>
            <b-col cols="12">
              <b-table hover responsive :fields="fields" :items="items" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" show-empty>
                <template #empty="">
                  <div class="align-items-center text-center">ไม่พบข้อมูล</div>
                </template>
                <template #emptyfiltered="">
                  <div class="align-items-center text-center">ไม่พบข้อมูล</div>
                </template>
                <template #cell(id)="data">
                  {{ data.value }}
                </template>
                <template #cell(image_url)="data">
                  <ul class="users-list align-items-center m-0 p-0">
                    <li
                      data-toggle="tooltip"
                      data-popup="tooltip-custom"
                      data-placement="bottom"
                      class="avatar pull-up m-0"
                    >
                      <img
                        class="media-object rounded"
                        :src="data.value"
                        alt="IMAgE"
                        height="50"
                        width="50"
                      >
                    </li>
                  </ul>
                </template>
                <template #cell(price)="data">
                  {{ toMoney(data.value) }}
                </template>
                <template #cell(point)="data">
                  {{ toMoney(data.value) }}
                </template>
                <template #cell(status)="data">
                  <p class='mb-0' :class="{ 'text-warning': data.value == 0, 'text-success': data.value == 1, 'text-danger': data.value > 1 }">
                    {{ data.value == 0 ? 'รอดำเนินการ' : data.value == 1 ? 'ดำเนินการเรียบร้อย' : 'ไม่สำเร็จ' }}
                  </p>
                </template>
                <template #cell(manage)="data">
                  <div class="btn-group">
                    <b-dropdown variant="link" toggle-class="bg-gradient-success waves-effect waves-light">
                      <template #button-content>
                        <span><feather-icon icon="EditIcon" class="font-medium-1 mr-1" /></span>จัดการ
                      </template>
                      <b-dropdown-item @click="modal_reward(data.item)" v-b-modal.modal-reward><span><feather-icon icon="EditIcon" class="text-warning mr-1" /></span>แก้ไข</b-dropdown-item>
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item @click="deleteReward(data.item)"><span><feather-icon icon="TrashIcon" class="text-danger mr-1" /></span>ลบ</b-dropdown-item>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <!-- End Table Body -->
          <!-- Table Footer -->
          <b-row>
            <b-col sm="12" md="5">
              <div role="status" aria-live="polite">แสดง {{ func.show_page(perPage, currentPage) }} ถึง {{ func.show_page_to(perPage, currentPage, recordsTotal) }} จาก {{func.toNum(totalRows)}} รายการ (ค้นหา จากทั้งหมด {{perPage}} รายการ)</div>
            </b-col>
            <b-col sm="12" md="7">
              <b-pagination
                v-on:change="onPageChange"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="end"
                size="sm"
                class="my-0"
                :first-number="true"
                :last-number="true"
              >
                <template #prev-text>‹ ย้อนกลับ</template>
                <template #next-text>ถัดไป ›</template>
              </b-pagination>
            </b-col>
          </b-row>
          <!-- End Table Footer -->
        </b-card-body>
      </div>
    </b-card>
    <!-- สิ้นสุดแถบแรก -->
    <b-modal
      id="modal-reward"
      centered
      title="สะสมแต้ม"
      ok-only
      ok-title="ตกลง"
      ok-variant=" bg-gradient-primary waves-effect waves-light"
      @ok="updateReward()"
    >
      <b-media
        class="mb-1"
        no-body
      >
          <b-avatar
            :src="reward_item.image_url"
            rounded
            variant=""
            class="mx-auto w-auto h-100"
            style="height: 200px!important"
          />
      </b-media>
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="รูปภาพ:"
        label-for="reward-image_url"
      >
        <b-form-input
          id="reward-image_url"
          v-model="reward_item.image_url"
          placeholder="ลิ้งค์รูปภาพ"
        />
      </b-form-group>
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="ชื่อสินค้า:"
        label-for="reward-name"
      >
        <b-form-input
          id="reward-name"
          v-model="reward_item.name"
          placeholder="ชื่อสินค้า"
          readonly
        />
      </b-form-group>
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="หมวดหมู่:"
        label-for="reward-category_name"
      >
        <b-form-input
          id="reward-category_name"
          v-model="reward_item.category_name"
          placeholder="หมวดหมู่"
          readonly
        />
      </b-form-group>
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="ราคาสินค้า:"
        label-for="reward-price"
      >
        <b-form-input
          id="reward-price"
          v-model="reward_item.price"
          placeholder="ราคาสินค้า"
          type="number"
          readonly
        />
      </b-form-group>
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="ยูสที่ได้รับ:"
        label-for="reward-username"
      >
        <b-form-input
          id="reward-username"
          v-model="reward_item.username"
          placeholder="ยูสที่ได้รับ"
          readonly
        />
      </b-form-group>
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="สถานะ:"
        label-for="reward-prefix"
      >
        <b-button 
          variant="btn-primary" 
          :class="{ 'bg-gradient-warning': reward_item.status == 0, 'bg-gradient-success': reward_item.status == 1, 'bg-gradient-danger': reward_item.status > 1 }" 
          class="btn waves-effect waves-light" 
          style="width: 170px"
          @click="toggleRewardType()"
        >
          {{ reward_item.status == 0 ? 'รอดำเนินการ' : reward_item.status == 1 ? 'ดำเนินการเรียบร้อย' : 'ไม่สำเร็จ' }}
        </b-button>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCol, BRow, BTable, BInputGroup, BInputGroupPrepend, BFormInput, BButton, BFormGroup, BInputGroupAppend, BFormSelect,
  BPagination, BDropdown, BDropdownItem, BDropdownDivider, BMedia, BMediaAside, BAvatar,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import func from '@core/comp-functions/misc/func'

export default {
  components: {
    BCard, BCardHeader, BCardBody,
    BCol, BRow, BTable,
    BInputGroup, BInputGroupPrepend, BFormInput, BFormGroup, BInputGroupAppend,
    BButton,
    BFormSelect,
    BPagination,
    BMedia, BMediaAside, BAvatar, 
    BDropdown, BDropdownItem, BDropdownDivider,
    flatPickr,
  },
  data() {
    return {
      func: func,
      BASE_URL: process.env.BASE_URL,
      date_start_report: '',
      date_end_report: '',
      draw: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      totalRows: 50,
      currentPage: 5,
      recordsTotal: 0,
      filter: '',
      filterOn: [],
      fields: [
        { key: 'id', label: '#', class: 'text-center' },
        { key: 'username', label: 'ยูส', class: 'text-center' },
        { key: 'image_url', label: 'รูปภาพ', class: 'text-center' },
        { key: 'name', label: 'ชื่อสินค้า', class: 'text-center' },
        { key: 'price', label: 'ราคาสินค้า', class: 'text-right' },
        { key: 'category_name', label: 'หมวดหมู่', class: 'text-center' },
        { key: 'type', label: 'ประเภท', class: 'text-right' },
        { key: 'status', label: 'สถานะ', class: 'text-center' },
        { key: 'created_at', label: 'วันที่', class: 'text-center' },
        { key: 'manage', label: 'จัดการข้อมูล', class: 'text-center' },
      ],
      items: [],
      reward_item: {},
      t_fetch: 0,
      bank_list: JSON.parse('{"01":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","02":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","03":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","04":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","05":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","06":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","07":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","08":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","09":"\u0e18.\u0e01.\u0e2a.","1":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","2":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","3":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","4":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","5":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","6":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","7":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","8":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","9":"\u0e18.\u0e01.\u0e2a."}'),
    }
  },
  mounted() {
    this.totalRows = this.items.length
    this.fetch()
    this.t_fetch = setInterval(() => {
      this.fetch()
    }, 1000 * 60)
  },
  watch: {
    filter: {
      handler() {
        this.fetch()
        this.totalRows = this.items.length
        this.currentPage = 1
      },
    }
  },
  methods: {
    toMoney(value) {
      const parts = parseFloat(value).toFixed(2).split('.')
      return parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + (parts[1] ? `.${parts[1]}` : '')
    },
    modal_reward(item) {
      this.reward_item = item
    },
    toggleRewardType() {
      if (this.reward_item.status != 1)
        this.reward_item.status = 1
      else
        this.reward_item.status = 0
    },
    updateReward() {
      this.loading()
      this.$http.post('log/reward/update', this.reward_item).then(res => {
        this.$swal.close()
        this.success()
      })
    },
    deleteReward(item) {
      this.loading()
      this.$http.post('log/reward/delete', item).then(res => {
        this.$swal.close()
        this.success()
      })
    },
    onFiltered(filteredItems) {
      // this.totalRows = filteredItems.length
      // this.currentPage = 1
    },
    onPageSelect() {
      let start = this.perPage * (this.currentPage - 1)
      this.fetch(start)
    },
    onPageChange(page) {
      let start = this.perPage * (page - 1)
      this.fetch(start)
    },
    loading() {
      this.$swal({
			  text: "กรุณารอสักครู่..",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: '',
        willOpen: () => {
          this.$swal.showLoading()
        },
      });
    },
    success() {
      this.$swal({
        title: 'แจ้งเตือน',
        text: 'แก้ไขสำเร็จแล้ว!',
        icon: 'success',
        confirmButtonText: 'เรียบร้อย',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    load(data) {
      if (data == null || data.data == null) return;
      this.draw = data.draw
      this.items = data.data
      this.totalRows = data.recordsFiltered
      this.recordsTotal = data.recordsTotal
    },
    fetch(start=0) {
      let url = `log/log_reward_list_page?draw=${this.draw}&start=${start}&length=${this.perPage}&search%5Bvalue%5D=${this.filter}&date_start=${this.date_start_report}&date_end=${this.date_end_report}&status=&_=${Date.now()}`;
      this.$http.get(url).then(res => { this.load(res.data) })
    },
  },
  beforeRouteLeave (to, from, next) {
    clearInterval(this.t_fetch)
    next()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
